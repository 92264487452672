<template>
  <v-card>
    <v-card-title class="main-red-bg white--text">
      <span class="text-h5">{{ subjectFormTitle }}</span>
    </v-card-title>
    <v-divider class="mb-2"></v-divider>
    <v-card-text>
      <v-form v-model="isSubjectFormValid" ref="subjectForm">
        <v-container>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="subjectFormData.name"
                label="Name"
                :rules="[validators.required]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="subjectFormData.code"
                label="Code"
                :rules="[validators.required, isSubjectCodeUnique]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="subjectFormData.gradeLevel"
                type="number"
                label="Grade Level"
                :rules="[validators.required, validGradeLevel]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="subjectFormData.icon"
                label="Icon"
                cache-items
                clearable
                :items="subjectIcons"
                :rules="[validators.required]"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="subjectFormData.color"
                label="Color"
                cache-items
                clearable
                :items="subjectColors"
                :rules="[validators.required]"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="subjectFormData.teacherIds"
                multiple
                chips
                label="Teacher"
                clearable
                :items="getTeachersAutocomplete"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" class="ma-2"> Subject Preview : </v-col>
            <v-col cols="12">
              <SubjectPreview :subject="subjectFormData" />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" class="ma-2 white--text px-3" @click="closeDialog">
        Cancel
      </v-btn>
      <v-btn
        :disabled="!isSubjectFormValid"
        :loading="loadingSubmit"
        @click="submitSubjectForm"
        color="success"
        class="ma-2 white--text px-3"
      >
        <v-icon left dark> mdi-send </v-icon>
        {{ formActionLabel }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import validators from "@/helpers/validationHelper";
import SubjectPreview from "@/components/SubjectPreview";
import { mapGetters } from "vuex";
export default {
  name: "SubjectForm",
  components: {
    SubjectPreview,
  },
  props: {
    subjectFormTitle: {
      type: String,
      default: "Add Subject",
    },
    isUpdating: {
      type: Boolean,
      default: false,
    },
    subjectData: {
      type: Object,
      default: () => ({
        name: "",
        code: "",
        gradeLevel: "",
        icon: "",
        category: "eng", //temporarily set as eng
        color: "",
        useCustomIcon: "no",
        teacherIds: [],
      }),
    },
  },
  data() {
    return {
      isSubjectFormValid: false,
      loadingSubmit: false,
      validators: validators,
      subjectIcons: [
        { text: "Culinary", value: "airballoon-outline" },
        { text: "Journalism", value: "book-open" },
        { text: "Literature", value: "book-open-page-variant" },
        { text: "Math", value: "calculator-variant-outline" },
        { text: "Statistics", value: "chart-box-outline" },
        { text: "Geology", value: "earth" },
        { text: "Science", value: "eyedropper-variant" },
        { text: "Finance", value: "finance" },
        { text: "Geometry", value: "format-list-numbered-rtl" },
        { text: "Social Science", value: "google-circles-extended" },
        { text: "Health", value: "heart-pulse" },
        { text: "History", value: "history" },
        { text: "TLE", value: "home" },
        { text: "Anthropology", value: "human" },
        { text: "Social Network", value: "human-greeting-proximity" },
        { text: "Correlational Research", value: "list-status" },
        { text: "Arts", value: "palette" },
        { text: "Hotel Service", value: "room-service-outline" },
        { text: "Disaster Ready (DRRD)", value: "shield-plus-outline" },
        { text: "Physics", value: "sigma" },
        { text: "English", value: "sort-alphabetical-ascending" },
        { text: "Descriptive research", value: "text-search" },
      ],
      subjectColors: [
        { text: "Black", value: "black" },
        { text: "Blue", value: "blue" },
        { text: "Brown", value: "brown" },
        { text: "Dark Blue", value: "dark-blue" },
        { text: "Green", value: "green" },
        { text: "Pink", value: "pink" },
        { text: "Purple", value: "purple" },
        { text: "Red", value: "red" },
        { text: "Teal", value: "teal" },
      ],
      subjectFormData: {
        name: "",
        code: "",
        gradeLevel: "",
        icon: "",
        category: "eng", //temporarily set as eng
        color: "",
        useCustomIcon: "no",
        teacherIds: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      getSubjectCodes: "_subjectsModule/getSubjectCodes",
      getTeachersAutocomplete: "usersModule/getTeachersAutocomplete",
    }),
    formActionLabel: function () {
      return this.isUpdating ? "Update" : "Submit";
    },
  },
  methods: {
    isSubjectCodeUnique(code) {
      if (this.isUpdating && this.subjectData.code === code) {
        return true;
      }
      return this.getSubjectCodes.includes(code) ? "Code already used" : true;
    },
    validGradeLevel(gradeLevel) {
      return gradeLevel > 0 && gradeLevel < 13 ? true : "Invalid Grade Level";
    },
    async submitSubjectForm() {
      this.loadingSubmit = true;
      const revalidatedForm = this.$refs.subjectForm.validate();
      if (revalidatedForm) {
        await this.$store.dispatch("_subjectsModule/submitSubjectForm", {
          subject: this.subjectFormData,
          isUpdating: this.isUpdating,
        });
      }
      this.loadingSubmit = false;
      this.closeDialog();
    },
    closeDialog() {
      this.$refs.subjectForm.reset();
      this.subjectFormData = {
        name: "",
        code: "",
        gradeLevel: "",
        icon: "",
        category: "eng", //temporarily set as eng
        color: "",
        useCustomIcon: "no",
        teacherIds: [],
      };
      this.$store.commit("_subjectsModule/clearSubjectData");
      this.$emit("closeDialog", this.isUpdating ? "edit" : "add");
    },
  },
  watch: {
    subjectData: {
      handler(newSubjectFormData) {
        if (this.isUpdating) {
          this.subjectFormData = newSubjectFormData;
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.$store.dispatch("usersModule/getUsers", {
      role: "teacher",
      sectionId: null,
    });
  },
};
</script>
