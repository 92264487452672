<template>
  <v-card class="elevation-2">
    <v-dialog v-model="subjectViewDialog" max-width="600px">
      <SubjectInfo @closeDialog="closeDialog($event)" />
    </v-dialog>
    <v-dialog v-model="subjectAddDialog" max-width="600px">
      <SubjectForm
        subjectFormTitle="Add Subject"
        @closeDialog="closeDialog($event)"
      />
    </v-dialog>
    <v-dialog v-model="subjectEditDialog" max-width="600px">
      <SubjectForm
        subjectFormTitle="Update Subject"
        :isUpdating="true"
        :subjectData="subjectData"
        @closeDialog="closeDialog($event)"
      />
    </v-dialog>
    <v-dialog v-model="subjectDeleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="main-red-bg white--text">
          <span class="text-h5">Delete Subject</span>
        </v-card-title>
        <v-divider class="mb-2"></v-divider>
        <v-card-text>
          <span class="text-h6">Are you sure you want to delete subject ?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="ma-2 white--text px-3"
            @click="closeDialog('delete')"
          >
            Cancel
          </v-btn>
          <v-btn
            @click="deleteSubject"
            :loading="deletingSubject"
            color="warning"
            class="ma-2 white--text px-3"
          >
            <v-icon left dark> mdi-delete </v-icon>
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title>
      <v-row>
        <v-col
          :class="$vuetify.breakpoint.xs ? 'justify-center' : ''"
          class="d-flex align-center"
          cols="12"
          sm="6"
        >
          <h2 class="mx-2">Subjects Table</h2>
        </v-col>
        <v-col
          :class="$vuetify.breakpoint.xs ? 'justify-center' : ''"
          class="d-flex justify-end"
          cols="12"
          sm="6"
        >
          <v-btn-toggle>
            <v-btn
              color="success"
              class="px-4 remove-toggled-state"
              light
              medium
              @click="openDialog({ action: 'add', subjectId: null })"
            >
              Add Subject
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col class="d-flex align-center" cols="12" sm="6">
          <FilterSelect
            filterLabel="Grade"
            :filterOptions="subjectGradeLevels"
            @change="filterSubjectsByGrade = $event"
          />
        </v-col>
        <v-col class="d-flex align-center" cols="12" sm="6">
          <SearchQuery @search="searchInputQuery = $event" />
        </v-col>
      </v-row>
    </v-card-title>
    <SubjectList
      :searchInput="searchInputQuery"
      :filterSubjectsByGrade="filterSubjectsByGrade"
      @openDialog="openDialog($event)"
    />
  </v-card>
</template>

<script>
import SearchQuery from "@/components/SearchQuery";
import FilterSelect from "@/components/FilterSelect";
import SubjectList from "@/components/SubjectList";
import SubjectForm from "@/components/SubjectForm";
import SubjectInfo from "@/components/SubjectInfo";
import { mapGetters } from "vuex";
export default {
  metaInfo: {
    title: "Subjects",
  },
  name: "SubjectIndex",
  components: {
    SearchQuery,
    FilterSelect,
    SubjectList,
    SubjectForm,
    SubjectInfo,
  },
  data() {
    return {
      filterSubjectsByGrade: "",
      searchInputQuery: "",
      subjectGradeLevels: ["7", "8", "9", "10", "11", "12"],
      subjectViewDialog: false,
      subjectAddDialog: false,
      subjectEditDialog: false,
      subjectDeleteDialog: false,
      deletingSubject: false,
    };
  },
  computed: {
    ...mapGetters({
      getSubjectData: "_subjectsModule/getSubjectData",
    }),
    subjectData() {
      return this.getSubjectData;
    },
  },
  methods: {
    async openDialog(dialogObject) {
      if (dialogObject.subjectId) {
        await this.$store.dispatch(
          "_subjectsModule/getSubjectById",
          dialogObject.subjectId
        );
      }
      switch (dialogObject.action) {
        case "view":
          this.subjectViewDialog = true;
          break;
        case "add":
          this.subjectAddDialog = true;
          break;
        case "edit":
          this.subjectEditDialog = true;
          break;
        case "delete":
          this.subjectDeleteDialog = true;
          break;
      }
    },
    closeDialog(dialog) {
      switch (dialog) {
        case "view":
          this.subjectViewDialog = false;
          break;
        case "add":
          this.subjectAddDialog = false;
          break;
        case "edit":
          this.subjectEditDialog = false;
          break;
        case "delete":
          this.subjectDeleteDialog = false;
          break;
      }
    },
    async deleteSubject() {
      const subjectId =
        this.$store.getters["_subjectsModule/getSubjectData"].id;
      this.deletingSubject = true;
      await this.$store.dispatch(
        "_subjectsModule/deleteSubjectById",
        subjectId
      );
      this.deletingSubject = false;
      this.closeDialog("delete");
    },
  },
};
</script>
<style scoped>
.remove-toggled-state {
  color: white !important;
}
</style>
