<template>
  <v-card>
    <v-card-title :class="`main-${subjectData.color}-bg white--text`">
      <span class="text-h5">Subject Details</span>
    </v-card-title>
    <v-divider class="mb-2"></v-divider>
    <v-card-text>
      <v-card elevation="2" outlined rounded="xl">
        <v-card-text
          :class="`subjectCard main-${subjectData.color}-bg`"
          class="d-flex justify-center align-center"
        >
          <v-sheet
            class="d-flex justify-center align-center"
            elevation="5"
            :class="`rounded-circle secondary-${subjectData.color}-bg`"
            height="90"
            width="90"
            :color="`main-${subjectData.color}`"
          >
            <div>
              <v-icon
                v-if="subjectData.iconType == 'font'"
                :color="subjectData.color"
                >mdi-{{ subjectData.icon }}</v-icon
              >
              <v-img v-else contain :src="subjectData.image"></v-img>
            </div>
          </v-sheet>
        </v-card-text>
        <v-card-title class="d-block word-break-unset">
          {{ subjectData.name }}
        </v-card-title>
        <v-card-subtitle>
          Grade level : {{ subjectData.gradeLevel }}
        </v-card-subtitle>
      </v-card>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-chip class="ma-2" :color="subjectData.color" outlined pill>
        <v-icon left> mdi-code-tags </v-icon>
        Code : {{ subjectData.code }}
      </v-chip>
      <v-btn color="error" class="ma-2 white--text px-3" @click="closeDialog">
        Back
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "SubjectInfo",
  computed: {
    subjectData: function () {
      return this.$store.getters["_subjectsModule/getSubjectData"];
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog", "view");
    },
  },
};
</script>
<style scoped>
.word-break-unset {
  word-break: unset;
}
</style>
