<template>
  <v-card outlined rounded="xl">
    <v-card-text
      :class="`subjectCard main-${subject.color}-bg`"
      class="d-flex justify-center align-center"
    >
      <v-sheet
        class="d-flex justify-center align-center"
        elevation="5"
        :class="`rounded-circle secondary-${subject.color}-bg`"
        height="90"
        width="90"
        color="main-blue"
      >
        <v-icon :color="subject.iconColor">mdi-{{ subject.icon }}</v-icon>
      </v-sheet>
    </v-card-text>
    <v-card-title @click="toolTip = !toolTip">
      <v-tooltip v-model="toolTip" bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="text-truncate">{{
            subject.name
          }}</span>
        </template>
        <span>{{ subject.name }}</span>
      </v-tooltip>
    </v-card-title>
    <v-card-subtitle>{{ `GRADE : ${subject.gradeLevel}` }} </v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: "SubjectPreview",
  props: {
    subject: Object,
    default: () => ({
      name: "",
      code: "",
      gradeLevel: "",
      icon: "",
      category: "eng", //temporarily set as eng
      color: "",
    }),
  },
  data() {
    return {
      toolTip: false,
    };
  },
};
</script>

<style scoped>
.subjectCard {
  background: var(--main-red);
  height: 130px;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
</style>
